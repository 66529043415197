import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import "./index.scss";
import { ConfigureStore } from "./redux/configureStore";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const store = ConfigureStore();

root.render(
  <React.StrictMode>
    <Provider {...{ store }}>
      <App />
    </Provider>
  </React.StrictMode>
);
